import React from 'react';
import Layout from "../components/layout";
import Seo from "../components/seo";
import "@fontsource/montserrat";

import { Link } from "gatsby";
import iconArrow from "../assets/svg/icon-right-arrow.svg";
import TypeIt from "typeit-react";

import ReactPlayer from 'react-player';


export default function IndexPage() {

  return (
    <Layout>
      <Seo title="Inicio" />
      <div className="index-wrapper">

        <div className="banner-page video">
          <div className="slide">

            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="content">
                    <TypeIt
                      options={{
                        false: true,
                        cursor: false,
                        speed: 100
                      }}
                    ><h1>Educando <br />en <span>Pandemia</span></h1></TypeIt>
                    <p className="my-4 animate__animated animate__fadeIn animate__delay-3s">El Covid-19 obligó a colegios de todo el mundo a cerrar sus puertas, suspendiendo en forma abrupta las clases presenciales. A continuación, testigos y protagonistas relatan los inmensos esfuerzos desplegados en Chile para continuar con la educación de los niños, pese a la pandemia.</p>
                    <Link className="animate__animated animate__fadeIn animate__delay-3s" to="/intervieweds">ENTRAR AL SITIO
                      <img src={iconArrow} width="20" className="m-0" alt="arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-bg">
              <div className="gradient"></div> 
              <ReactPlayer
                muted={true}
                controls={false}
                width="100%"
                height="100%"
                autoPlay={true}
                loop={true}
                playing={true}
                playsinline={true}
                style={{
                  zIndex: -1
                }}
                url='https://educacionoh.udd.cl/videos/home/video_portada.mp4' />
            </div>

          </div>

          
        </div>
      </div>
    </Layout>
  )


}